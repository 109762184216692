import type { NormalizedRequestModel } from '@/services/models/Http';
import type { Nullable } from '@/type-utils';
import { InvalidStateError, NotImplementedError } from '@/utils/errors';
import { cache } from 'react';

/**
 * This interface represents the context of the running code.
 */
export interface IRunContext {
  /**
   * The request that is being processed.
   */
  request: NormalizedRequestModel;
}

/**
 * The `RunContextService` fills a hole in our `CurrentRequestService`. It is used to store
 * and retrive relevant information about the state of the current context. Currently
 * this is only useful for ServerComponents, if used in a client component it will throw
 * a `NotImplementedError`. Right now this error is expected in the `I18nService` and ignored.
 *
 * TODO: In the long run, after we have all routes on the App Router, we should reconsider
 * both this service and the `CurrentRequestService` and see what kind of refactor makes sense.
 */
export class RunContextService {
  private request: Nullable<NormalizedRequestModel> = null;

  private getContext: (() => NormalizedRequestModel) | undefined;

  /**
   * A getter that gets the cached context.
   * @returns The run context.
   */
  public get context(): IRunContext {
    try {
      if (!this.getContext) {
        this.getContext = cache(() => this.request!);
      }

      const context = this.getContext();

      if (!context) {
        throw new InvalidStateError(
          'Run context has not been stored. Do not retrieve it without storing it first.'
        );
      }

      return { request: context };
    } catch (e) {
      if (e instanceof InvalidStateError) {
        throw e;
      }

      throw new NotImplementedError(
        'The react Cache cannot be used outside of a Server Component.'
      );
    }
  }

  /**
   * Stores the context in the service.
   * @param req - The request to store.
   */
  public storeContext(req: NormalizedRequestModel): void {
    this.request = req;
    const { context } = this;
  }
}

export default new RunContextService();
